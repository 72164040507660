import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Alert from '@common/services/alert';
import { Button } from '@common/components/button';
import { FileInput } from '@common/components/form/inputs/file';
import { Modal } from '@common/components/modal';
import { Checkbox } from '@common/components/form/inputs/checkbox';
import Attachments from '@common/components/form/attachments/attachments';

import { addFile, replaceFile } from '@modules/documents/api';
import * as fileUtil from '@common/utils/file';
import { useChangeFileInput } from '@common/components/form/inputs/file/hooks';
import type { Attachment, FileInProgress } from '@common/types/objects';
import { useAppSelector } from '@common/hooks';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { ResponseDocument } from '@modules/documents/types';

type Props = {
  children?: React.ReactElement;
  parentFolderId: string | undefined;
  documentId?: string;
  userId?: string;
  personalFolder: boolean;
  onUpdate?: (item: ResponseDocument) => void;
};

const MAX_AMOUNT_OF_FILES = 10;

export const FileUploadForm = ({
  children, parentFolderId, documentId, userId, personalFolder, onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentOrgId);

  const [shouldNotify, setShouldNotify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const multiple = documentId ? false : MAX_AMOUNT_OF_FILES;
  const { files, setFiles, onRemove, onChange } = useChangeFileInput(multiple);

  const onClose = () => {
    setFiles([]);
    setShouldNotify(false);
    setShowModal(false);
  };

  const onUpload = async () => {
    setLoading(true);
    try {
      if (documentId) {
        const res = await replaceFile(orgId, documentId, files[0].id);
        onUpdate?.(res);
      } else {
        await Promise.all(files.map(
          async (file) => {
            const res = await addFile(orgId, file as Attachment, userId, shouldNotify, parentFolderId, personalFolder);
            onUpdate?.(res);
          }
        ));
      }
      Alert.success(documentId ?
        t('documents:replaced_file_successfully') :
        t('documents:added_file_successfully', { count: files.length }));
      onClose();
    } catch (e) {
      Alert.error(documentId ?
        t('documents:replaced_file_error') :
        t('documents:added_file_error', { count: files.length }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      list
      title={documentId ? t('documents:replace_file') : t('documents:upload_files')}
      className="DocumentUploadForm"
      show={showModal}
      onShow={() => setShowModal(true)}
      onClose={onClose}
      content={(
        <>
          <FileInput
            maxFileSize={500}
            value={multiple ? files : files[0]}
            multiple={multiple}
            onChange={onChange}
          />
          <div className="DocumentUploadForm__Attachments">
            <Attachments
              fields={files as (Attachment & FileInProgress)[]}
              onRemove={onRemove}
            />
          </div>
        </>
      )}
      footerNote={(
        <Checkbox
          label={t('documents:notify_team')}
          disabled={!!documentId || personalFolder}
          value={shouldNotify}
          onChange={setShouldNotify}
        />
      )}
      footer={(
        <Button
          type="primary"
          disabled={files?.length === 0 || fileUtil.isProcessingAttachments(files)}
          isLoading={loading}
          onClick={onUpload}
        >
          {t('documents:upload')}
        </Button>
      )}
    >
      {children || (
        <Button type="primary" size="large" icon="upload">
          {t('documents:upload')}
        </Button>
      )}
    </Modal>
  );
};
