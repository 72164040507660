import { useCallback, useEffect, useRef, useState } from 'react';
import { useSWRConfig } from 'swr';

import { useAppSelector } from '@common/hooks';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';

import { fetchDocuments, fetchDocumentsOptimized } from '../api';
import { Document } from '../types';
import { createPersonalFolderObj } from '../utils/create-personal-folder';
import { DocumentsContextType } from '../context';
import { Filters } from '../constants';

type UseFolderDocumentsProps = DocumentsContextType & {
  searchParams?: { search?: string },
  filter?: Filters | null,
  folderId?: string,
  loggedUserId?: string,
  userId?: string,
};

export const useFolderDocuments = ({
  searchParams = {}, filter = null, folderId, loggedUserId, userId, setDocuments,
}: UseFolderDocumentsProps) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const { mutate } = useSWRConfig();
  const orgId = useAppSelector(getCurrentOrgId);
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [folderDocuments, setFolderDocuments] = useState<Record<string, Document> | undefined>(undefined);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [endpoints, setEndpoints] = useState<string[] | undefined>(undefined);
  const { data: initialData, isLoading, endpoint } = fetchDocumentsOptimized(orgId, {
    folderId, searchTerm: searchParams.search, filter, userId,
  });
  if (!endpoints?.includes(endpoint)) setEndpoints((prev) => [...(prev || []), endpoint]);

  useEffect(() => {
    setDocuments?.((prev) => ({ ...prev, ...folderDocuments }));
  }, [folderDocuments, setDocuments]);

  useEffect(() => {
    if (initialData && !isLoading) {
      const hasPersonalFolder = !folderId && !filter && loggedUserId && initialData?.meta.has_personal_documents;
      setFolderDocuments(initialData?.data.reduce((acc: Record<string, Document>, item: Document) => {
        acc[item.id] = item;
        return acc;
      }, hasPersonalFolder ? { personal: createPersonalFolderObj(loggedUserId) } : {}));
      setCursor(initialData?.meta.pagination.next_cursor || undefined);
    }
  }, [loggedUserId, initialData, folderId, filter, setFolderDocuments]);

  const invalidate = useCallback(() => {
    (endpoints || []).forEach((key) => mutate(key));
  }, [mutate, endpoints]);

  const onLoadMore = useCallback(async () => {
    setIsFetchingMore(true);
    try {
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      const { data, meta } = await fetchDocuments(orgId, {
        nextCursor: cursor,
        searchTerm: searchParams?.search,
        filter,
        folderId,
        userId,
      }, abortControllerRef.current);
      setFolderDocuments((prev) => data?.reduce((acc: Record<string, Document>, item) => {
        acc[item.id] = item;
        return acc;
      }, prev || {}));
      setCursor(meta.pagination.next_cursor || undefined);
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') return;
      throw error;
    } finally {
      setIsFetchingMore(false);
    }
  }, [
    orgId, folderId, filter, searchParams.search, abortControllerRef, cursor, userId,
    setCursor, setDocuments, setIsFetchingMore, setFolderDocuments,
  ]);

  const onRemove = useCallback((documentId: string) => {
    setFolderDocuments((prev) => {
      const newDocuments = { ...prev };
      delete newDocuments[documentId];
      return newDocuments;
    });
    invalidate();
  }, [setFolderDocuments, invalidate]);

  const onUpdate = useCallback((folder: Document) => {
    setFolderDocuments((prev) => ({
        ...prev,
      [folder.id]: { ...(prev?.[folder.id] || {}), ...folder },
    }));
    invalidate();
  }, [setFolderDocuments, invalidate]);

  const onClear = useCallback(() => {
    setFolderDocuments(undefined);
  }, [setFolderDocuments]);

  return {
    documents: folderDocuments,
    canLoadMore: !!cursor,
    isFetching: isLoading,
    isFetchingMore,
    invalidate,
    onLoadMore,
    onRemove,
    onUpdate,
    onClear,
  };
};
