import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertService } from '@services/alert';
import { moveDocument } from '@modules/documents/api';
import { useAppSelector } from '@common/hooks';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { PickFolderModal, PickFolderModalProps } from '../pick-folder-modal';
import type { Document } from '../../types';

type MoveDocumentModalProps = Omit<PickFolderModalProps, 'onPick'> & {
  documentItem: Document;
  onUpdate?: (document: Document) => void;
  onMove?: (documentId: string) => void;
};

export const MoveDocumentModal = ({
  children,
  documentItem,
  initialIsVisible,
  onClose,
  onUpdate,
  onMove,
}: MoveDocumentModalProps) => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentOrgId);
  const context = documentItem.is_folder ? 'folder' : 'file';

  const handleMoveFile: ComponentProps<typeof PickFolderModal>['onPick'] = async ({
    handleClose,
    selectedFolder,
  }) => {
    if (documentItem.parent_folder_id === selectedFolder?.id ||
      (!documentItem.parent_folder_id && !selectedFolder?.id)) {
      handleClose();
      return;
    }
    try {
      await moveDocument(orgId, documentItem.id, selectedFolder?.id || null);
      AlertService.success(t('documents:move_successfully', { context }));
      onMove?.(documentItem.id);
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('documents:move_warning', { context }),
          error: t('documents:move_error', { context }),
        });
      }
    } finally {
      handleClose();
    }
  };

  return (
    <PickFolderModal
      initialIsVisible={initialIsVisible}
      onClose={onClose}
      onPick={handleMoveFile}
      onUpdate={onUpdate}
    >
      {children}
    </PickFolderModal>
  );
};
