import moment from 'moment';

import { Filters } from '../../constants';

import type { SortDirection } from '@common/types/values';
import type { Document } from '../../types';

export const sortDocuments = (filter: Filters | undefined) => (
  items: Document[],
  sortKey: keyof Document,
  sortDirection: SortDirection,
) => {
  if (!sortKey) return items;

  const clonedItems = [...items];

  clonedItems.sort((a, b) => {
    if (sortKey === 'updated_at') {
      const fieldToSortBy: keyof Document = filter === Filters.TRASH ? 'deleted_at' : sortKey;

      if (a[fieldToSortBy] === b[fieldToSortBy]) return 0;

      if (sortDirection === 'desc') {
        return moment(a[fieldToSortBy]).isBefore(moment(b[fieldToSortBy])) ? 1 : -1;
      }

      return moment(a[fieldToSortBy]).isBefore(moment(b[fieldToSortBy])) ? -1 : 1;
    }

    if (a[sortKey] === b[sortKey]) return 0;

    if (typeof a[sortKey] === 'string') {
      if (sortDirection === 'desc') {
        return (b[sortKey] as string || '').localeCompare(a[sortKey] as string || '', undefined, { sensitivity: 'base' });
      }

      return (a[sortKey] as string || '').localeCompare(b[sortKey] as string || '', undefined, { sensitivity: 'base' });
    }

    if (sortDirection === 'desc') {
      return (a[sortKey] as number) > (b[sortKey] as number) ? -1 : 1;
    }

    return (a[sortKey] as number) > (b[sortKey] as number) ? 1 : -1;
  });

  clonedItems.sort((a, b) => {
    if (a.type === 'personal_folder') return -1;
    if (b.type === 'personal_folder') return 1;

    if (!a.is_folder && b.is_folder) return 1;
    if (a.is_folder && !b.is_folder) return -1;
    return 0;
  });

  return clonedItems;
};
