import { PersonalFolder } from '../types';

export const createPersonalFolderObj = (loggedUserId: string): PersonalFolder => ({
  id: 'personal',
  type: 'personal_folder',
  name: 'personal',
  is_folder: true,
  personal_root_user_id: loggedUserId,
  organisation_id: '',
  user_id: loggedUserId,
  parent_folder_id: '',
  is_favorited: false,
  created_at: '',
  updated_at: '',
  deleted_at: '',
  access_settings: {},
  last_opened_at: '',
});
