import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FileViewer } from '@common/components/file-viewer';
import { Button } from '@common/components/button';

import type { Document, File } from '../../types';

type DocumentsFileViewerProps = {
  documents: Document[];
  fileInPreview?: File;
  setFileInPreview: (newFile: File | undefined) => void;
  handleToggleDocumentFavoriteState: (item: File) => Promise<void>;
};

export const DocumentsFileViewer = ({
  documents,
  fileInPreview,
  setFileInPreview,
  handleToggleDocumentFavoriteState,
}: DocumentsFileViewerProps) => {
  const { t } = useTranslation();

  const files = useMemo(() => {
    return documents
      .filter((item): item is File => !item.is_folder)
      // Fixes https://linear.app/oneteam/issue/ONE-2086/documents-renaming-file-does-not-update-the-name-in-the-file-viewer
      // When renamed the attachment file_name remains the original one (should be fixed on the API)
      .map((item) => ({ ...item, attachment: { ...item.attachment, file_name: item.name } }));
  }, [documents]);

  const fileViewerActionButtons = useCallback((currentContent: File) => currentContent && (
    <Button
      icon={currentContent.is_favorited ? 'star__filled' : 'star'}
      type="dark"
      size="large"
      onClick={() => handleToggleDocumentFavoriteState(currentContent)}
    >
      {t(currentContent.is_favorited ? 'documents:remove_from_favorites' : 'documents:add_to_favorites')}
    </Button>
  ), []);

  if (!fileInPreview) return null;

  return (
    <FileViewer
      isVisible
      content={files}
      setIsVisible={(isVisible) => !isVisible && setFileInPreview(undefined)}
      initialPage={files.findIndex(({ id }) => id === fileInPreview.id)}
      moreActionButtons={fileViewerActionButtons}
    />
  );
};
