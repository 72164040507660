import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useAppSelector } from '@common/hooks';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { fetchDocuments, fetchParents } from '../api';
import { Document, DocumentsRouteParams, Folder } from '../types';
import { useDocuments } from '../context';

export const useCurrentFolder = () => {
  const orgId = useAppSelector(getCurrentOrgId);
  const { documents, setDocuments, isFetchingParents, setIsFetchingParents } = useDocuments();
  const { folderId } = useParams<DocumentsRouteParams>();
  useEffect(() => {
    const loadParents = async () => {
      let parentDocuments: Document[] = [];
      setIsFetchingParents?.(true);
      const parents = await fetchParents(orgId, folderId);
      await Promise.all(parents.map(async (parentFolder) => {
        if (!documents?.[parentFolder.id]) {
          const { data } = await fetchDocuments(orgId, { folderId: parentFolder.id });
          parentDocuments = [...parentDocuments, ...data];
        }
      }));
      const { data: rootDocuments } = await fetchDocuments(orgId, { folderId: undefined });
      const { data: personalDocuments } = await fetchDocuments(orgId, { folderId: 'personal' });
      parentDocuments = [...parentDocuments, ...personalDocuments, ...rootDocuments];
      setDocuments?.((prev) => parentDocuments.reduce((acc: Record<string, Document>, item) => {
        acc[item.id] = item;
        return acc;
      }, prev || {}));
      setIsFetchingParents?.(false);
    };
    if (!isFetchingParents && folderId && !documents?.[folderId] && folderId !== 'personal') {
      loadParents();
    }
  }, [orgId, folderId, isFetchingParents, documents, setIsFetchingParents]);
  return folderId ? documents?.[folderId] as Folder : undefined;
};
