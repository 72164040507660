import { omit } from 'lodash';
import useSWR from 'swr';

import { Api } from '@common/services/api';
import { Filters } from './constants';
import { Document, Folder, ResponseDocument } from './types';
import { ListApiResponse } from '@common/types/util-types';
import { Attachment } from '@common/types/objects';

type FetchDocumentsParams = FetchDocumentsFilters & {
  nextCursor?: string | null;
  limit?: number;
};

type UploadFileApiResponse = {
  data: ResponseDocument;
};

type FetchDocumentsApiResponse = ListApiResponse<ResponseDocument[]>;
type FetchDocumentApiResponse = ListApiResponse<ResponseDocument>;

type FetchDocumentsFilters = {
  filter?: Filters | null;
  sort_key?: 'created_at' | 'updated_at' | 'name';
  order?: 'asc' | 'desc';
  folderId?: string;
  searchTerm?: string;
  userId?: string;
};

export type CreateDocumentPayload = Pick<Folder, 'parent_folder_id' | 'is_folder' | 'name'> & {
  access_settings: {
    networkIds?: string[];
    functionIds?: string[];
  }
};

export type FolderFormValues = Pick<Folder, 'name' | 'access_settings' | 'parent_folder_id' | 'is_folder'>;

const fetchDocumentsEndpoint = (orgId: string, {
  nextCursor = null, userId, folderId, searchTerm, filter, sort_key, order, limit = 50,
}: FetchDocumentsParams) => {
  const endpoint = (() => {
    const baseEndpoint = userId
      ? `/v1/organisations/${orgId}/users/${userId}/documents`
      : `/v1/organisations/${orgId}/documents`;
    if (searchTerm) return `${baseEndpoint}/search`;
    if (folderId === 'personal') return `${baseEndpoint}/me`;
    if (folderId) return `${baseEndpoint}/folders/${folderId}`;
    if (filter) return `${baseEndpoint}/${filter}`;
    return baseEndpoint;
  })();
  const sortBy = sort_key && `${sort_key}_${order}`;
  const query = Api.utils.toQuery({
    limit: Math.min(filter === Filters.RECENT ? 30 : 50, limit),
    cursor: nextCursor || true,
    sort_by: sortBy,
    q: searchTerm,
  });
  return `${endpoint}?${query}`;
};

export const addFile = async (
  orgId: string, attachment: Attachment, userId?: string, notify_users?: boolean,
  parentFolderId?: string, personalFolder?: boolean,
) => {
  const endpoint = userId ? `/v1/organisations/${orgId}/users/${userId}/documents` : `/v1/organisations/${orgId}/documents`;
  const payload = {
    name: attachment.file_name,
    file_id: attachment.id,
    is_folder: false,
    parent_folder_id: parentFolderId === 'personal' ? undefined : parentFolderId,
  };
  const { data } = await Api.post<UploadFileApiResponse>(endpoint, personalFolder ? payload : {
    ...payload,
    notify_users,
  });
  return data;
};

export const createFolder = async (orgId: string, payload: CreateDocumentPayload, userId?: string) => {
  const endpoint = userId ? `/v1/organisations/${orgId}/users/${userId}/documents` : `/v1/organisations/${orgId}/documents`;
  const { data } = await Api.post<FetchDocumentApiResponse>(endpoint, userId ? omit(payload, ['access_settings']) : payload);
  return data;
};

export const deleteDocument = async (permanent: boolean, orgId: string, documentId: string, userId?: string) => {
  if (userId) {
    await Api.delete(`/v1/organisations/${orgId}/users/${userId}/documents/${documentId}`);
  } else {
    await Api.delete(`/v1/organisations/${orgId}/documents/${documentId}${permanent ? '/trash' : ''}`);
  }
};

export const fetchDocumentsOptimized = (orgId: string, options: FetchDocumentsParams) => {
  const endpoint = fetchDocumentsEndpoint(orgId, options);
  const { data, isLoading } = useSWR(endpoint, () => Api.get<FetchDocumentsApiResponse>(endpoint));
  return { data, isLoading, endpoint };
};

export const fetchDocuments = async (orgId: string, options: FetchDocumentsParams, abortController?: AbortController) => {
  const endpoint = fetchDocumentsEndpoint(orgId, options);
  const res = await Api.get<FetchDocumentsApiResponse>(endpoint, undefined, { signal: abortController?.signal });
  return res;
};

export const fetchParents = async (orgId: string, folderId?: string) => {
  const { data } = await Api.get<FetchDocumentsApiResponse>(`/v1/organisations/${orgId}/documents/${folderId}/parents`);
  return data;
};

export const markDocumentAsViewed = async (orgId: string, documentId: string) => {
  if (documentId === 'personal') return;
  const { data } = await Api.post<{ data: Document }>(`/v1/organisations/${orgId}/documents/${documentId}/open`);
  return data;
};

export const moveDocument = async (orgId: string, documentId: string, newParentFolderId?: string | null) => {
  await Api.put<{ success: boolean }>(`/v1/organisations/${orgId}/documents/${documentId}`, {
    parentFolderId: newParentFolderId,
  });
};

export const restoreDocument = async (orgId: string, documentId: string, newParentFolderId?: string | null) => {
  await Api.post<{ success: boolean }>(`/v1/organisations/${orgId}/documents/${documentId}/trash/restore`, {
    parentFolderId: newParentFolderId,
  });
};

export const renameDocument = async (orgId: string, documentId: string, name: string) => {
  const { data } = await Api.put<{ data: Document }>(`/v1/organisations/${orgId}/documents/${documentId}`, { name });
  return data;
};

export const replaceFile = async (orgId: string, documentId: string, fileId: string) => {
  const { data } = await Api.put<UploadFileApiResponse>(`/v1/organisations/${orgId}/documents/${documentId}`, {
    file_id: fileId,
  });
  return data;
};

export const toggleFavorite = async (orgId: string, documentId: string) => {
  const { data } = await Api.post<{ data: Document }>(`/v1/organisations/${orgId}/documents/${documentId}/favorite`);
  return data;
};

export const updateFolder = async (
  orgId: string,
  documentId: string,
  values: Partial<CreateDocumentPayload>,
  userId?: string,
) => {
  const endpoint = userId ?
    `/v1/organisations/${orgId}/users/${userId}/documents/folders/${documentId}` :
    `/v1/organisations/${orgId}/documents/${documentId}`;
  const { data } = await Api.put<FetchDocumentApiResponse>(endpoint, values);
  return data;
};
