import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { combineClassNames } from '@utils/combineClassNames';
import { bytesToSize } from '@utils/file';

import Badge from '@common/components/badge';
import Stack from '@common/components/stack';
import Icon from '@common/components/icon';
import { DocumentIcon } from '../document-icon';

import { Filters } from '../../constants';
import { Document } from '@modules/documents/types';
import { EStatus } from '@common/definitions';

type DocumentBrowserItemProps = {
  item: Document;
  filter?: Filters;
  handleItemOnClick: (item: Document) => void;
};

const DocumentBrowserItem = ({
  item, filter, handleItemOnClick,
}: DocumentBrowserItemProps) => {
  const { t } = useTranslation();
  const today = moment();
  const deletedAt = filter === Filters.TRASH && moment(item.deleted_at);

  if (item.type === 'personal_folder') {
    return [
      <>
        <div className="Document__Icon">
          <DocumentIcon item={item} />
        </div>
        <div>
          <div className="Table__Cell__Title" onClick={() => handleItemOnClick(item)} role="link">
            <a>{t('documents:personal_documents')}</a>
          </div>
        </div>
      </>,
      null,
      null,
    ];
  }

  const iconAndStatus = useMemo(() => {
    return (
      <>
        <div className="Document__Icon">
          <DocumentIcon item={item} />
        </div>
        <Stack direction="row" gap={2}>
          <div className="Table__Cell__Title" onClick={() => handleItemOnClick(item)} role="link">
            <a className={combineClassNames('Document__Title', {
                '!tw-text-gray-400': !item.name,
              })}
            >
              {item.name || t('knowledge_base:title_empty')}
            </a>
            {item.is_favorited && <Icon type="star__filled" />}
          </div>
          {/* @ts-expect-error */}
          {item.status === EStatus.DRAFT && <Badge status={EStatus.DRAFT} />}
        </Stack>
      </>
    );
  }, [item]);

  return [
    iconAndStatus,
    deletedAt
      ? (
        <>
          {deletedAt.format('D MMM YYYY')}
          &nbsp;
          <span className="Document__DeletedIn">
            {t('documents:days_left', { count: 14 - today.diff(deletedAt, 'days') })}
          </span>
        </>
      )
      : moment(item.updated_at).format('D MMM YYYY'),
    !item.is_folder ? bytesToSize(item.attachment?.file_size) : '-',
  ];
};

export default DocumentBrowserItem;
