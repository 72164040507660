import React, { useEffect, useRef } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Modal } from '@common/components/modal';
import { Button } from '@common/components/button';
import { AlertService } from '@services/alert';
import { TextInput } from '@common/components/form';

import { useAppSelector } from '@common/hooks';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { renameDocument } from '@modules/documents/api';
import type { Document } from '../../types';

type FormState = {
  name: string;
};

type ReduxFormProps = InjectedFormProps<FormState, RenameDocumentOwnProps, boolean>;
const reduxFormConnector = reduxForm<FormState, RenameDocumentOwnProps, boolean>({
  form: 'rename-document',
  initialValues: {
    name: '',
  },
});

type RenameDocumentOwnProps = {
  document: Document;
  onClose: () => void;
  onUpdate: (document: Document) => void;
};

type RenameDocumentProps = RenameDocumentOwnProps & ReduxFormProps;

export const UnconnectedRenameDocumentModal = ({
  document,
  onClose,
  onUpdate,
  initialize,
  handleSubmit,
  dirty,
  submitting,
}: RenameDocumentProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const orgId = useAppSelector(getCurrentOrgId);

  useEffect(() => initialize(document), [document]);

  useEffect(() => {
    if (inputRef.current) {
      const { value } = inputRef.current;
      inputRef.current.focus();

      const extRegex = /\.\w{2,4}$/;
      if (extRegex.test(value)) {
        const extensionPosition = value.search(extRegex);
        inputRef.current.setSelectionRange(0, extensionPosition);
      }
    }
  }, [inputRef.current]);

  const submitHandler = handleSubmit(async ({ name }) => {
    const context = document.is_folder ? 'folder' : 'file';
    try {
      const res = await renameDocument(orgId, document.id, name);
      onUpdate?.(res);
      onClose();
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('documents:renamed_warning', { context }),
          error: t('documents:renamed_error', { context }),
        });
      }
    }
  });

  return (
    <Modal
      show
      disableRootClose
      title={t('documents:rename')}
      wrapper={Modal.FormWrapper}
      wrapperProps={{ onSubmit: submitHandler }}
      size="small"
      onClose={onClose}
      content={<TextInput name="name" ref={inputRef} />}
      footer={(
        <Button
          buttonType="submit"
          type="primary"
          isLoading={submitting}
          disabled={!dirty}
        >
          {t('common:change')}
        </Button>
      )}
    />
  );
};

export const RenameDocumentModal = reduxFormConnector(UnconnectedRenameDocumentModal);
