import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { TableDropdown } from '@common/components/table';
import { FileUploadForm } from '../../forms/file-upload';

import { openInNewTab } from '@utils/open-in-new-tab';
import { Filters } from '../../constants';
import type { Document, Folder } from '../../types';
import { useFolderPermissions } from '@modules/documents/hooks/use-folder-permissions';

type Props = {
  basePath?: string;
  item: Document;
  filter?: Filters;
  setDocumentToShowDetails: (document: Document) => void;
  setFolderForEdit: (folder: Folder) => void;
  setDocumentToDelete: (value: Document) => void;
  setDocumentToMove: (document: Document) => void;
  setDocumentToRename: (document: Document) => void;
  handleRestoreDocument: (documentId: string) => void;
  handleToggleDocumentFavoriteState: (item: Document) => void;
  onUpdate: (document: Document) => void;
};

export const DocumentActions = ({
  item,
  basePath,
  filter,
  setDocumentToShowDetails,
  setFolderForEdit,
  setDocumentToDelete,
  setDocumentToMove,
  setDocumentToRename,
  handleRestoreDocument,
  handleToggleDocumentFavoriteState,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    canUpdate, canRemove, getCanUpdateFolder, getCanRemoveFolder,
    hasOrgansationDocumentsCreate, hasOrgansationDocumentsUpdate,
  } = useFolderPermissions();

  const canUpdateFolder = item.is_folder &&
    (item.personal_root_user_id || getCanUpdateFolder(item));
  const canRemoveFolder = item.is_folder &&
    (item.personal_root_user_id || getCanRemoveFolder(item));

  if (item.type === 'personal_folder') return null;

  return (
    <TableDropdown id={`document-${item.id}`}>
      {filter === Filters.TRASH && hasOrgansationDocumentsCreate ? (
        <TableDropdown.Item
          icon="restore"
          onClick={() => handleRestoreDocument(item.id)}
        >
          {t('documents:restore')}
        </TableDropdown.Item>
      ) : (
        <>
          {!item.is_folder && (
            <>
              <TableDropdown.Item
                icon="download"
                onClick={() => {
                  if (item.page) {
                    history.push(`${basePath}/page/${item.id}/print`);
                  } else {
                    openInNewTab(item.attachment.download_path!, true);
                  }
                }}
              >
                {t('documents:download')}
              </TableDropdown.Item>
              {!item.personal_root_user_id && hasOrgansationDocumentsUpdate && (
                <TableDropdown.Item
                  icon="folder"
                  onClick={() => setDocumentToMove(item)}
                >
                  {t('documents:move_to')}
                </TableDropdown.Item>
              )}
              {canUpdate && (
                <TableDropdown.Item
                  icon="edit"
                  onClick={() => setDocumentToRename(item)}
                >
                  {t('documents:rename')}
                </TableDropdown.Item>
              )}
              {!item.page && canUpdate && (
                <FileUploadForm
                  parentFolderId={item.parent_folder_id || undefined}
                  documentId={item.id}
                  personalFolder={!!item.personal_root_user_id}
                  onUpdate={onUpdate}
                >
                  <TableDropdown.Item icon="sync">
                    {t('documents:replace_file')}
                  </TableDropdown.Item>
                </FileUploadForm>
              )}
            </>
          )}
          {canUpdateFolder && (
            <TableDropdown.Item
              icon="edit"
              onClick={item.is_folder ? () => setFolderForEdit(item) : undefined}
            >
              {t('documents:edit_folder')}
            </TableDropdown.Item>
          )}
          <TableDropdown.Item icon="info" onClick={() => setDocumentToShowDetails(item)}>
            {t('documents:details_and_activity')}
          </TableDropdown.Item>
          {!item.personal_root_user_id && (
            <TableDropdown.Item icon="star" onClick={() => handleToggleDocumentFavoriteState(item)}>
              {t(item.is_favorited ? 'documents:remove_from_favorites' : 'documents:add_to_favorites')}
            </TableDropdown.Item>
          )}
        </>
      )}
      {(canRemoveFolder || (!item.is_folder && canRemove)) && (
        <TableDropdown.Item danger icon="delete" onClick={() => setDocumentToDelete(item)}>
          {t('common:delete')}
        </TableDropdown.Item>
      )}
    </TableDropdown>
  );
};
