import React, { useState, cloneElement, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useToggle } from '@hooks/index';
import { useFolderDocuments } from '@modules/documents/hooks/use-folder-documents';
import { combineClassNames } from '@utils/combineClassNames';

import { PureList } from '@common/components/list';
import { Modal } from '@common/components/modal';
import { Icon } from '@common/components/icon';
import { Button } from '@common/components/button';
import { DocumentIcon } from '../document-icon';
import FolderModalForm from '../../forms/folder-modal-form';
import GridShowMoreButton from '@common/components/grid-show-more';
import Spinner from '@common/components/spinner';

import type { Document, DocumentsRouteParams, Folder } from '../../types';
import { sortDocuments } from '@modules/documents/containers/documents-container/sort-documents';

type MoveDocumentListRowProps = {
  item: Document;
  navigationHandler: (folderKey: string) => void;
};

export const PickFolderListRow = ({ item, navigationHandler }: MoveDocumentListRowProps) => {
  const fullClassName = combineClassNames('PickFolderModal__Row', {
    'PickFolderModal__Row--disabled': !item.is_folder,
  });

  return (
    <div role="option" className={fullClassName} onClick={item.is_folder ? () => navigationHandler(item.id) : undefined}>
      <div className="PickFolderModal__DocumentIcon">
        <DocumentIcon item={item} />
      </div>
      <div>
        <div className="PickFolderModal__Title">
          <a>{item.name}</a>
          {item.is_favorited && <Icon type="star__filled" />}
        </div>
      </div>
      {item.is_folder && <Icon type="chevron_right" />}
    </div>
  );
};

type OnPickProps = {
  selectedFolder: Folder | undefined;
  handleClose: () => void;
};

export type PickFolderModalProps = {
  children?: ReactElement;
  initialIsVisible?: boolean;
  onPick: (props: OnPickProps) => void | Promise<void>;
  acceptButtonLabel?: string;
  onClose?: () => void;
  onUpdate?: (document: Document) => void;
};

export const PickFolderModal = ({
  children,
  initialIsVisible = false,
  onPick,
  acceptButtonLabel,
  onClose,
  onUpdate,
}: PickFolderModalProps) => {
  const { t } = useTranslation();
  const { folderId } = useParams<DocumentsRouteParams>();
  const [documents, setDocuments] = useState<Record<string, Document> | undefined>(undefined);
  const [currentFolderId, setCurrentFolderId] = useState<string>();
  const {
    documents: folderDocuments, canLoadMore, isFetching, isFetchingMore, onLoadMore,
  } = useFolderDocuments({ documents, setDocuments, folderId: currentFolderId });
  const [isVisible, toggleIsVisible] = useToggle(initialIsVisible);

  const currentDocument = currentFolderId ? documents?.[currentFolderId] : undefined;
  const currentFolder = currentDocument?.is_folder ? currentDocument : undefined;
  const folders = sortDocuments(undefined)(Object.values(folderDocuments || {}), 'name', 'asc');

  const handleNavigateBack = () => setCurrentFolderId(currentFolder?.parent_folder_id || undefined);
  const handleClose = () => {
    toggleIsVisible();
    onClose?.();
  };
  const handlePick = () => onPick?.({
    handleClose,
    selectedFolder: currentFolder,
  });

  return (
    <Modal
      className="PickFolderModal"
      show={isVisible}
      header={(
        <div className="PickFolderModal__Header">
          {currentFolder && <Icon type="chevron_left" role="button" onClick={handleNavigateBack} />}
          <span>{currentFolder?.name || t('documents:filter_all')}</span>
        </div>
      )}
      onClose={handleClose}
      footerNote={(
        <FolderModalForm currentFolder={currentFolder} onUpdate={onUpdate}>
          <Button iconRight="add" className="pull-left">
            {t('documents:create_folder')}
          </Button>
        </FolderModalForm>
      )}
      footer={(
        <Button
          type="primary"
          onClick={handlePick}
          disabled={currentFolder?.id === folderId}
        >
          {acceptButtonLabel || t('common:move')}
        </Button>
      )}
      content={(
        <>
          <PureList
            key={currentFolderId}
            items={folders}
            renderRow={PickFolderListRow}
            rowProps={{
              navigationHandler: setCurrentFolderId,
            }}
            isLoading={isFetching}
            placeholder={(
              <span className="PickFolderModal__Placeholder">
                {isFetching ? <Spinner /> : t('documents:empty_folder')}
              </span>
            )}
          />
          {!isFetching && canLoadMore && (
            <GridShowMoreButton
              onShowMore={onLoadMore}
              isFetching={isFetchingMore}
            />
          )}
        </>
      )}
    >
      {children && cloneElement(children, { onClick: toggleIsVisible })}
    </Modal>
  );
};
