import { generatePath, useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { Table } from '@common/components/table';
import Spinner from '@common/components/spinner';
import { ConfirmButton } from '@common/components/confirm-button';
import { AlertService } from '@common/services/alert';

import FolderModalForm from '@modules/documents/forms/folder-modal-form';
import DocumentBrowserItem from './document-browser-item';
import { DocumentActions } from '../document-actions';
import { DocumentsFileViewer } from '../documents-file-viewer';
import { DocumentsPlaceholder } from '../documents-placeholder';
import { PickFolderModal, PickFolderModalProps } from '../pick-folder-modal';
import { MoveDocumentModal } from '../move-document-modal';
import { DocumentDetail } from '../document-detail';
import { RenameDocumentModal } from '@modules/documents/forms/rename-document-modal';

import { useAppSelector } from '@common/hooks';
import { useCurrentFolder } from '@modules/documents/hooks/use-current-folder';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { deleteDocument, markDocumentAsViewed, restoreDocument, toggleFavorite } from '@modules/documents/api';
import { sortDocuments } from '@modules/documents/containers/documents-container/sort-documents';
import { Document, DocumentsRouteParams, File, Folder } from '@modules/documents/types';
import { Filters } from '@modules/documents/constants';

import './documents-browser.scss';

type DocumentsBrowserProps = {
  documents?: Record<string, Document>;
  userId?: string;
  basePath?: string;
  isFetchingMore?: boolean;
  isFetching?: boolean;
  canLoadMore?: boolean;
  getFolderPath: (folderId: string) => string;
  onLoadMore: () => void;
  onRemove: (documentId: string) => void;
  onUpdate: (folder: Document) => void;
  onClear: () => void;
};

const DocumentsBrowser = ({
  documents, userId, basePath,
  isFetching = false, isFetchingMore = false, canLoadMore = false,
  onLoadMore, onRemove, getFolderPath, onUpdate, onClear,
}: DocumentsBrowserProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const orgId = useAppSelector(getCurrentOrgId);
  const currentFolder = useCurrentFolder();
  const { filter, folderId, networkId } = useParams<DocumentsRouteParams>();

  const [folderToOpenInTrash, setFolderToOpenInTrash] = useState<Document | undefined>(undefined);
  const [fileInPreview, setFileInPreview] = useState<File | undefined>(undefined);
  const [documentToDelete, setDocumentToDelete] = useState<Document | undefined>(undefined);
  const [selectNewFolderToRestoreDocumentTo, setSelectNewFolderToRestoreDocumentTo] = useState<Document | undefined>(undefined);
  const [documentToShowDetails, setDocumentToShowDetails] = useState<Document | undefined>(undefined);
  const [folderForEdit, setFolderForEdit] = useState<Folder | undefined>(undefined);
  const [documentToMove, setDocumentToMove] = useState<Document | undefined>(undefined);
  const [documentToRename, setDocumentToRename] = useState<Document | undefined>(undefined);

  const columns = useMemo(() => {
    return [
      {
        size: '40%',
        label: t('documents:label_name'),
        className: 'Document__Name',
        sort_key: 'name' as keyof Document,
      }, {
        size: '25%',
        label: filter === 'trash' ? t('documents:label_deleted') : t('documents:label_modified'),
        sort_key: 'updated_at' as keyof Document,
      }, {
        size: '25%',
        label: t('documents:label_file_size'),
      },
    ];
  }, [filter, t]);

  const handleRemove = useCallback(async () => {
    if (!documentToDelete) return;
    const context = documentToDelete?.is_folder ? 'folder' : 'file';
    try {
      await deleteDocument(filter === Filters.TRASH, orgId, documentToDelete.id, userId);
      onRemove(documentToDelete.id);
      AlertService.success(t('documents:removed_successfully', { context }));
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('documents:warning_removing_failed', { context }),
          error: t('documents:error_removing_failed', { context }),
        });
      }
    } finally {
      setDocumentToDelete(undefined);
    }
  }, [documentToDelete, userId]);

  const handleItemOnClick = useCallback((item: Document) => {
    if (filter === Filters.TRASH || !!item.deleted_at) {
      setFolderToOpenInTrash(item);
      return;
    }
    if (item.page && basePath) return history.push(
      `${generatePath(basePath, { networkId })}/${folderId ? `${folderId}/` : ''}page/${item.id}`);
    markDocumentAsViewed(orgId, item.id);
    if (item.is_folder) {
      onClear();
      return history.push(generatePath(getFolderPath(item.id), { networkId }));
    }
    setFileInPreview(item);
  }, [basePath, folderId, history, getFolderPath, filter]);

  const handleRestoreDocument = useCallback(async (documentId: string, newParentId?: string | null) => {
    const context = folderToOpenInTrash?.is_folder ? 'folder' : 'file';
    try {
      await restoreDocument(orgId, documentId, newParentId); // First call to check if it can be restored without moving
      onRemove(documentId);
      AlertService.success(t('documents:restore_success', { context }));
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        if (e.status_code === 422 && !folderToOpenInTrash) {
          return setSelectNewFolderToRestoreDocumentTo(documents?.[documentId]); // If we couldn't restore it normally, open move document modal
        }
        AlertService.forStatus(e.status_code, {
          warning: t('documents:restore_warning', { context }),
          error: t('documents:restore_error', { context }),
        });
      }
    }
  }, [orgId, documents, setSelectNewFolderToRestoreDocumentTo]);

  const handleOnPickRestore: PickFolderModalProps['onPick'] = useCallback(async ({ selectedFolder, handleClose }) => {
    if (selectNewFolderToRestoreDocumentTo) {
      await handleRestoreDocument(selectNewFolderToRestoreDocumentTo.id, selectedFolder?.id || null);
    }
    handleClose();
  }, [selectNewFolderToRestoreDocumentTo, handleRestoreDocument]);

  const handleToggleDocumentFavoriteState = async (item: Document) => {
    const { is_favorited: isFavorited } = item;
    try {
      const res = await toggleFavorite(orgId, item.id);
      onUpdate(res);
      if (isFavorited && filter === Filters.FAVORITES) onRemove(item.id);
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t(isFavorited
            ? 'documents:warning_while_removing_from_favorites'
            : 'documents:warning_while_adding_to_favorites'),
          error: t(isFavorited
            ? 'documents:error_while_removing_from_favorites'
            : 'documents:error_while_adding_to_favorites'),
        });
      }
    }
  };

  return (
    <>
      <Table
        columns={columns}
        items={(!isFetching && documents && Object.values(documents)) || []}
        renderRow={DocumentBrowserItem}
        rowProps={{
          basePath,
          filter,
          handleItemOnClick,
          setDocumentToDelete,
          handleRestoreDocument,
          setDocumentToShowDetails,
          setFolderForEdit,
          setDocumentToMove,
          setDocumentToRename,
          handleToggleDocumentFavoriteState,
          onUpdate,
        }}
        sortingDisabled={filter === Filters.RECENT}
        initialSortState={{
          key: filter === Filters.RECENT ? 'last_opened_at' : 'name',
          direction: filter === Filters.RECENT ? 'desc' : 'asc',
        }}
        sortingHandler={sortDocuments(filter)}
        ActionComponent={DocumentActions}
        placeholder={!isFetching && <DocumentsPlaceholder filter={filter} />}
      />
      {(isFetching || isFetchingMore) && <Spinner centered />}
      {!isFetching && !isFetchingMore && canLoadMore && (
        <div
          className="Table__ShowMore"
          onClick={onLoadMore}
          role="button"
        >
          {t('common:table_show_more')}
        </div>
      )}
      {!isFetching && documents && (
        <DocumentsFileViewer
          documents={Object.values(documents)}
          fileInPreview={fileInPreview}
          setFileInPreview={setFileInPreview}
          handleToggleDocumentFavoriteState={handleToggleDocumentFavoriteState}
        />
      )}
      {documentToShowDetails && (
        <DocumentDetail
          item={documentToShowDetails}
          onClose={() => setDocumentToShowDetails(undefined)}
        />
      )}
      {selectNewFolderToRestoreDocumentTo && (
        <PickFolderModal
          initialIsVisible
          onPick={handleOnPickRestore}
          onClose={() => setSelectNewFolderToRestoreDocumentTo(undefined)}
        />
      )}
      {documentToMove && (
        <MoveDocumentModal
          initialIsVisible
          documentItem={documentToMove}
          onClose={() => setDocumentToMove(undefined)}
          onMove={onRemove}
          onUpdate={onUpdate}
        />
      )}
      {folderForEdit && (
        <FolderModalForm
          initialIsVisible
          folder={folderForEdit}
          initialValues={folderForEdit}
          currentFolder={currentFolder}
          onUpdate={onUpdate}
          onClose={() => setFolderForEdit(undefined)}
          userId={userId}
        />
      )}
      {documentToRename && (
        <RenameDocumentModal
          document={documentToRename}
          onUpdate={onUpdate}
          onClose={() => setDocumentToRename(undefined)}
        />
      )}
      {documentToDelete && (
        <ConfirmButton
          danger
          title={t(filter === Filters.TRASH ?
            'documents:remove_permanently' : 'documents:remove',
            { context: documentToDelete.is_folder ? 'folder' : 'file', name: documentToDelete.name })}
          description={t(filter === Filters.TRASH ?
            'documents:assert_user_wants_to_delete_permanently' : 'documents:assert_user_wants_to_delete',
            { context: documentToDelete.is_folder ? 'folder' : 'file' })}
          confirmText={t('common:delete')}
          onConfirm={handleRemove}
          onCancel={() => setDocumentToDelete(undefined)}
        />
      )}
      {folderToOpenInTrash && (
        <ConfirmButton
          title={t('documents:folder_is_deleted')}
          confirmText={t('documents:restore')}
          description={t('documents:restore_if_you_want_open')}
          onCancel={() => setFolderToOpenInTrash(undefined)}
          onConfirm={() => {
            handleRestoreDocument(folderToOpenInTrash.id);
            setFolderToOpenInTrash(undefined);
          }}
        />
      )}
    </>
  );
};

export default memo(DocumentsBrowser);
