import { useCallback } from 'react';

import { useCurrentFolder } from '@modules/documents/hooks/use-current-folder';
import { useAppSelector, useCanAccess } from '@common/hooks';
import { Folder } from '@modules/documents/types';
import { EPermissions } from '@common/definitions';
import * as loggedUserSelector from '@modules/core/selectors/logged-user';

const PERMISSIONS = {
  create: [EPermissions.ORGANISATION_DOCUMENTS_CREATE, EPermissions.NETWORK_DOCUMENTS_CREATE],
  update: [EPermissions.ORGANISATION_DOCUMENTS_UPDATE, EPermissions.NETWORK_DOCUMENTS_UPDATE],
  remove: [EPermissions.ORGANISATION_DOCUMENTS_REMOVE, EPermissions.NETWORK_DOCUMENTS_REMOVE],
};

export const useFolderPermissions = () => {
  const currentFolder = useCurrentFolder();
  const networks = useAppSelector(loggedUserSelector.networks);

  const getCanAccess = useCallback((type: keyof typeof PERMISSIONS, folder?: Folder) => {
    return useCanAccess({
      permissions: PERMISSIONS[type][0],
      networkPermissions: PERMISSIONS[type][1],
      networks: folder?.access_settings.networks || networks,
    });
  }, [networks]);

  return {
    hasOrgansationDocumentsCreate: useCanAccess({ permissions: PERMISSIONS.create[0] }),
    hasOrgansationDocumentsUpdate: useCanAccess({ permissions: PERMISSIONS.update[0] }),
    hasOrgansationDocumentsRemove: useCanAccess({ permissions: PERMISSIONS.remove[0] }),
    canCreate: getCanAccess('create', currentFolder),
    canUpdate: getCanAccess('update', currentFolder),
    canRemove: getCanAccess('remove', currentFolder),
    getCanCreateFolder: (folder: Folder) => getCanAccess('create', folder),
    getCanUpdateFolder: (folder: Folder) => getCanAccess('update', folder),
    getCanRemoveFolder: (folder: Folder) => getCanAccess('remove', folder),
  };
};
