import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../button';

type GridShowMoreButtonProps = {
  onShowMore: () => Promise<void>,
  isFetching: boolean,
};

const GridShowMoreButton = ({ onShowMore, isFetching }: GridShowMoreButtonProps) => {
  const { t } = useTranslation();
  return (
    <div className="Grid__ShowMore">
      <Button
        onClick={!isFetching ? onShowMore : undefined}
        disabled={isFetching}
        isLoading={isFetching}
        type="soft"
      >
        {t('common:table_show_more')}
      </Button>
    </div>
  );
};

export default GridShowMoreButton;
