import React, {
  Dispatch, SetStateAction, createContext, useContext, useMemo, useState,
} from 'react';
import { Document } from './types';

type ValueType = Record<string, Document>;

export type DocumentsContextType = {
  documents?: ValueType;
  setDocuments: Dispatch<SetStateAction<ValueType | undefined>>;
  isFetchingParents?: boolean;
  setIsFetchingParents?: Dispatch<SetStateAction<boolean>>;
};

const DocumentsContext = createContext<DocumentsContextType>({
  documents: undefined,
  setDocuments: () => {},
  isFetchingParents: false,
  setIsFetchingParents: () => {},
});

const DocumentsProvider = ({ children }: { children: React.ReactNode }) => {
  const [documents, setDocuments] = useState<ValueType | undefined>(undefined);
  const [isFetchingParents, setIsFetchingParents] = useState(false);
  const value = useMemo(() => ({
    documents,
    setDocuments,
    isFetchingParents,
    setIsFetchingParents,
  }), [documents, isFetchingParents]);
  return (
    <DocumentsContext.Provider value={value}>
      {children}
    </DocumentsContext.Provider>
  );
};

const useDocuments = () => {
  return useContext(DocumentsContext);
};

export { DocumentsProvider, useDocuments };
